import Auth0Wrapper from "../PrivateRoute/Auth0Wrapper";
import BaseVideoApp from "./BaseVideoApp";

const HostVideoApp = () => {
  return (
    <Auth0Wrapper>
      <BaseVideoApp />
    </Auth0Wrapper>
  );
};

export default HostVideoApp;
