import AppStateProvider from "./state";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth0ProviderWithHistory from "./components/PrivateRoute/Auth0ProviderWithHistory";
import VideoRoutes from "./routes/VideoCallRoutes";
import PhoneCallRoutes from "./routes/PhoneCallRoutes";
import theme from "./theme";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

export const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <UnsupportedBrowserWarning>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <AppStateProvider>
              <Routes>
                {/* Protected Routes for voice calls */}
                <Route path="/calls/*" element={<PhoneCallRoutes />} />

                {/* Routes for video calls */}
                <Route path="*" element={<VideoRoutes />} />
              </Routes>
            </AppStateProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </UnsupportedBrowserWarning>
    </MuiThemeProvider>
  );
};

export default App;
