import { Room, TwilioError } from "twilio-video";
import { useEffect } from "react";

import { Callback } from "../../../types";

export default function useHandleRoomDisconnection(
  room: Room | null,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void,
  callbacks: {
    onError: Callback;
    onSuccess: Callback;
  }
) {
  const { onError, onSuccess } = callbacks;
  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          // use onSuccess if room ended by host
          if (error.code === 53118) {
            onSuccess({
              name: "Room Ended",
              message: "Room ended by host"
            });
          } else {
            onError(error);
          }
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
      };

      room.on("disconnected", onDisconnected);
      return () => {
        room.off("disconnected", onDisconnected);
      };
    }
  }, [room, onError, onSuccess, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare]);
}
