import {
  ClinicianMedicareRole,
  MedicareItem,
  MedicareItemFormat,
  MedicareItemMode,
  MedicareItemDuration
} from "./interfaces";

export const PSYCHOLOGIST_ITEMS: MedicareItem[] = [
  {
    mbsCode: "10968",
    description:
      "Psychology health service provided by an eligible psychologist, lasting at least 20 minutes, to a person who has a chronic condition and complex care needs being managed by a medical practitioner (including a general practitioner, but not a specialist or consultant physician) under a shared care plan or under both a GP Management Plan and Team Care Arrangements or, if the person is a resident of an aged care facility, the person's medical practitioner has contributed to a multidisciplinary care plan.",
    benefit: 60.35,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: "81000",
    description:
      "Provision of a non-directive pregnancy support counselling service, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible psychologist, where the patient is referred to the psychologist by a medical practitioner.",
    benefit: 70.85,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan30
  },
  {
    mbsCode: "82360",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient in consulting rooms by an eligible psychologist, lasting more than 20 minutes but less than 50 minutes.",
    benefit: 68.5,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "82362",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient at a place other than consulting rooms by an eligible psychologist, lasting more than 20 minutes but less than 50 minutes.",
    benefit: 93.15,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "82363",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient in consulting rooms by an eligible psychologist, lasting more than 20 minutes but less than 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "82365",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient at a place other than consulting rooms by an eligible psychologist, lasting at least 50 minutes.",
    benefit: 121.35,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "82366",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient in person as part of a group of 6 to 10 patients by an eligible psychologist, lasting at least 60 minutes.",
    benefit: 24.65,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: "82367",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient by video conference as part of a group of 6 to 10 patients by an eligible psychologist, lasting at least 60 minutes.",
    benefit: 24.65,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: "93084",
    description:
      "Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible psychologist, lasting at least 20 minutes but less than 50 minutes.",
    benefit: 68.5,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "93087",
    description:
      "Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible psychologist, lasting at least 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "93118",
    description:
      "Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible psychologist, lasting at least 20 minutes but less than 50 minutes.",
    benefit: 68.5,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "93121",
    description:
      "Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible psychologist, lasting at least 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "93026",
    description:
      "Provision of a non-directive pregnancy support counselling service as a telehealth attendance, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible psychologist, where the patient is referred to the mental health nurse by a medical practitioner.",
    benefit: 70.85,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan30
  },
  {
    mbsCode: "93029",
    description:
      "Provision of a non-directive pregnancy support counselling service as a phone attendance, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible psychologist, where the patient is referred to the mental health nurse by a medical practitioner.",
    benefit: 70.85,
    role: ClinicianMedicareRole.Psychologist,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan30
  }
];
