import { Button, makeStyles } from "@material-ui/core";
import BackgroundThumbnail from "../../../BackgroundSelectionDialog/BackgroundThumbnail/BackgroundThumbnail";
import CloseIcon from "../../../../icons/CloseIcon";
import { backgroundConfig } from "../../../VideoProvider/useBackgroundSettings/useBackgroundSettings";

const { imageNames, images } = backgroundConfig;

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "300px",
    maxWidth: "300px",
    padding: "8px 12px",
    background: "#FFF",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
    overflow: "hidden",
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "12px",
    minWidth: 0,
    padding: 0
  },
  overlayTitle: {
    maxWidth: "calc(100% - 30px)",
    marginBottom: "8px",
    color: "#414449",
    fontSize: "12px",
    fontWeight: 600
  },
  backgroundContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    maxHeight: "256px",
    gap: "8px",
    overflowY: "auto"
  },
  backgroundItem: {
    flex: "1 0 calc(50% - 4px)",
    width: "auto",
    margin: 0
  }
}));

interface BackgroundSelectProps {
  onClose?: () => void;
}

const BackgroundSelect = ({ onClose }: BackgroundSelectProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button className={classes.closeButton} variant="text" onClick={onClose}>
        <CloseIcon size={16} color="#414449" stroke strokeWidth="2" />
      </Button>
      <div className={classes.overlayTitle}>Backgrounds</div>
      <div className={classes.backgroundContainer}>
        <BackgroundThumbnail className={classes.backgroundItem} thumbnail={"none"} name={"None"} />
        <BackgroundThumbnail className={classes.backgroundItem} thumbnail={"blur"} name={"Blur"} />
        {images.map((image, index) => (
          <BackgroundThumbnail
            key={index}
            className={classes.backgroundItem}
            thumbnail={"image"}
            name={imageNames[index]}
            index={index}
            imagePath={image}
          />
        ))}
      </div>
    </div>
  );
};

export default BackgroundSelect;
