import { useMemo, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import AvatarIcon from "../../icons/AvatarIcon";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";
import PinIcon from "./PinIcon/PinIcon";
import ScreenShareIcon from "../../icons/ScreenShareIcon";
import Typography from "@material-ui/core/Typography";

import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import { useAppState } from "../../state";
import { useAuth0 } from "@auth0/auth0-react";
import { removeParticipant } from "../../services/schedService";
import { useVideoContext } from "../VideoProvider";
import { getIdentityName } from "../helpers/getIdentityName";
import WarningIcon from "../../icons/WarningIcon";
import { getDevicePermissionsErrorMessage } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      isolation: "isolate",
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: 0,
      overflow: "hidden",
      marginBottom: "0.5em",
      "& video": {
        objectFit: "contain !important"
      },
      borderRadius: "4px",
      border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
      background: "black",
      [theme.breakpoints.down("sm")]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: "8px",
        marginBottom: "0",
        fontSize: "12px",
        paddingTop: `${theme.sidebarMobileHeight - 2}px`
      }
    },
    innerContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    infoContainer: {
      position: "absolute",
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "100%",
      background: "transparent",
      top: 0
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "black",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        "& svg": {
          transform: "scale(0.7)"
        }
      }
    },
    warningContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "32px 16px",
      zIndex: 1,

      "&:before": {
        content: '""',
        background: "black",
        opacity: 0.3,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1
      },

      [theme.breakpoints.down("md")]: {
        padding: 0
      }
    },
    warningText: {
      paddingLeft: "10px",
      color: "white",
      textAlign: "center"
    },
    reconnectingContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(40, 42, 43, 0.75)",
      zIndex: 1
    },
    screenShareIconContainer: {
      background: "rgba(0, 0, 0, 0.5)",
      padding: "0.18em 0.3em",
      marginRight: "0.3em",
      display: "flex",
      "& path": {
        fill: "white"
      }
    },
    identity: {
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "0.18em 0.3em 0.18em 0",
      margin: 0,
      display: "flex",
      alignItems: "center"
    },
    infoRowBottom: {
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      bottom: 0,
      left: 0
    },
    typography: {
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem"
      }
    },
    hideParticipant: {
      display: "none"
    },
    cursorPointer: {
      cursor: "pointer"
    },
    galleryView: {
      border: `${theme.participantBorderWidth}px solid ${theme.galleryViewBackgroundColor}`,
      borderRadius: "8px",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        width: "100%",
        height: "100%",
        padding: "0",
        fontSize: "12px",
        margin: "0",
        "& video": {
          objectFit: "cover !important"
        }
      }
    },
    dominantSpeaker: {
      border: "solid 2px #7BEAA5"
    },
    removeParticipantBtn: {
      padding: "4px 2px",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer"
      }
    },
    removeLoader: {
      padding: "4px 2px"
    }
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker
}: ParticipantInfoProps) {
  /**
   * If user is defined, meaning it is a host
   */
  const { user } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const publications = usePublications(participant);

  /**
   * Only host can remove participants,
   * Can remove other participants only
   */
  const allowToRemove = !!user?.isClinician && !isLocalParticipant;

  const [isRemoving, setIsRemoving] = useState(false);

  const audioPublication = publications.find((p) => p.kind === "audio" && !p.trackName.includes("audioshare"));
  const videoPublication = publications.find((p) => p.kind === "video" && !p.trackName.includes("screenshare"));

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find((p) => p.trackName.includes("screenshare"));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const { isGalleryViewActive } = useAppState();
  const { allParticipants, room, updateParticipantsList } = useVideoContext();
  const classes = useStyles();

  const handleRemoveParticipant = async () => {
    if (!allowToRemove) {
      return;
    }
    setIsRemoving(true);
    const token = await getAccessTokenSilently();
    const {
      data: { participants }
    } = await removeParticipant(token, room!.name, participant.identity);
    if (participants) {
      updateParticipantsList(participants);
    }
    setIsRemoving(false);
  };

  const participantInfo = useMemo(() => {
    const foundParticipant = allParticipants.find(({ participantId }) => participant.identity === participantId);
    const permissionsErrorMessage = getDevicePermissionsErrorMessage(
      foundParticipant?.isCameraPermissionsDenied,
      foundParticipant?.isMicrophonePermissionsDenied
    );

    return {
      participantName: foundParticipant?.name || getIdentityName(participant.identity),
      isCameraPermissionsDenied: foundParticipant?.isCameraPermissionsDenied,
      isMicrophonePermissionsDenied: foundParticipant?.isMicrophonePermissionsDenied,
      permissionsErrorMessage
    };
  }, [allParticipants, participant.identity]);

  return (
    <div
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick),
        [classes.dominantSpeaker]: isDominantSpeaker,
        [classes.galleryView]: isGalleryViewActive
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <NetworkQualityLevel participant={participant} />
        <div className={classes.infoRowBottom}>
          {isScreenShareEnabled && (
            <span className={classes.screenShareIconContainer}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography variant="body1" className={classes.typography} component="span">
              {participantInfo.participantName}
              {isLocalParticipant && " (You)"}
            </Typography>
            {allowToRemove && !isRemoving && (
              <div className={classes.removeParticipantBtn} onClick={handleRemoveParticipant}>
                Remove Participant
              </div>
            )}
            {isRemoving && <div className={classes.removeLoader}>Removing...</div>}
          </span>
        </div>
        <div>{isSelected && <PinIcon />}</div>
      </div>
      <div className={classes.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && !participantInfo.isCameraPermissionsDenied && (
          <div className={classes.avatarContainer}>
            <AvatarIcon />
          </div>
        )}
        {isParticipantReconnecting ? (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typography}>
              Reconnecting...
            </Typography>
          </div>
        ) : (
          participantInfo.permissionsErrorMessage && (
            <div className={classes.warningContainer}>
              <WarningIcon width="50px" height="50px" fill="#ffffff" />
              <span className={classes.warningText}>{participantInfo.permissionsErrorMessage}</span>
            </div>
          )
        )}
        {children}
      </div>
    </div>
  );
}
