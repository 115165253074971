import { useState, useEffect, useCallback } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import RoomNameScreen from "./RoomNameScreen/RoomNameScreen";
import { useNavigate, useParams } from "react-router-dom";
import { useVideoContext } from "../VideoProvider";
import { useAppState } from "../../state";
import { TelehealthRoomType } from "../../services/schedService";

export enum Steps {
  roomNameStep,
  deviceSelectionStep
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { mediaError, hasAttemptedAcquireLocalTracks, acquireLocalMedia, initialiseTelehealthRoomType } =
    useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const navigate = useNavigate();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState("");
  const [roomName, setRoomName] = useState("");

  const handleAcquireLocalMedia = useCallback(
    async (telehealthRoomType: TelehealthRoomType) => {
      if (!hasAttemptedAcquireLocalTracks) {
        await acquireLocalMedia(telehealthRoomType);
      }
    },
    [acquireLocalMedia, hasAttemptedAcquireLocalTracks]
  );

  const handleRoomNameSubmit = async (roomId: string) => {
    const telehealthRoomType = await initialiseTelehealthRoomType(roomId);

    window.history.replaceState(
      null,
      "",
      window.encodeURI(`${user?.isClinician ? "/clinicians" : ""}/room/${roomId}${window.location.search || ""}`)
    );
    setStep(Steps.deviceSelectionStep);
    handleAcquireLocalMedia(telehealthRoomType);
  };

  useEffect(() => {
    if (URLRoomName) {
      if (URLRoomName.endsWith(".")) {
        navigate(`/room/${URLRoomName.slice(0, -1)}`);
      }

      setRoomName(URLRoomName);

      if (user) {
        handleRoomNameSubmit(URLRoomName);
      }
    }

    // adding handleRoomNameSubmit to this list would cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, URLRoomName, navigate, handleAcquireLocalMedia]);

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={user?.nickname || name}
          roomName={URLRoomName || roomName}
          setName={setName}
          setRoomName={setRoomName}
          hideRoomNameField={!!URLRoomName}
          onSubmit={handleRoomNameSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={user?.name || name} roomName={roomName} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
