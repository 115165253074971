import { useEffect, useMemo, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AppointmentBasicDetails from "./AppointmentBasicDetails";
import {
  AppointmentSlots,
  AppointmentType,
  ClaimType,
  DeliveryType,
  FormError,
  Practitioner,
  Provider,
  ServiceDeliveredOptions,
  mbsItemLookup,
  needResetPrepareClaim
} from "./helpers";
import AppointmentMoreDetails from "./AppointmentMoreDetails";
import AppointmentInvoiceDetails from "./AppointmentInvoiceDetails";
import AppointmentClaimDetails from "./AppointmentClaimDetails";
import moment from "moment";
import { TIME_FORMAT } from "../../utils/dateTimeFormat";
import { ProcessAppointmentPayload, SubmitForReviewPayload } from "../../services/schedService";
import clsx from "clsx";
import LoadingForm from "./LoadingForm";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MBS_CODE_FULL_LIST } from "./mbsItems";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      fontFamily: "Nunito Sans"
    },
    loaderWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: "1 auto",
      alignItems: "center",
      justifyContent: "center"
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "20px"
    },
    title: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px"
    },
    desc: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "27px"
    },
    closeButton: {
      cursor: "pointer",
      background: "transparent",
      border: "0"
    },
    sessionTitle: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#414449"
    },
    actionWrapper: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      gap: "0.5rem",
      width: "100%"
    },
    alertText: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1.125rem",
      color: "#414449"
    },
    confirmButton: {
      background: "linear-gradient(234deg, #988337 -10.24%, #C5B000 106.12%)",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#fff",
      padding: "12px 16px",
      "&.MuiButton-contained.Mui-disabled": {
        cursor: "not-allowed",
        background: "#d9d7ca",
        color: "#fff"
      }
    },
    submitForReview: {
      display: "flex",
      width: "100%",
      gap: "20px"
    },
    textarea: {
      flex: 1,
      height: "48px",
      borderRadius: "4px",
      border: "1px solid #dadbde",
      resize: "none",
      padding: "12px",
      fontFamily: "'Nunito Sans', sans-serif",
      fontSize: "16px"
    },
    errorTextarea: {
      border: "1px solid #d54141"
    },
    submitForReviewButton: {
      background: "linear-gradient(54deg, #3F52FF -11.82%, #00109F 111.69%)",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#fff",
      padding: "12px 16px",
      minWidth: "fit-content"
    },
    infoIcon: {
      fontSize: "20px"
    },
    changeCodeTypeInfo: {
      display: "flex",
      gap: "12px",
      padding: "4px 12px",
      borderRadius: "4px",
      alignItems: "center",
      background: "#d9dcff",
      fontSize: "12px"
    }
  })
);

interface ProcessAppointmentFormProps {
  appointment?: AppointmentSlots;
  isLoading: boolean;
  appointmentTypes: AppointmentType[];
  providers: Provider[];
  isSubmitting: boolean;
  onProcess: (payload: ProcessAppointmentPayload) => void;
  onSubmitForReview: (payload: SubmitForReviewPayload) => void;
  selectedPractitioner: Practitioner;
}

const ProcessAppointmentForm = ({
  appointment,
  isLoading,
  appointmentTypes,
  providers,
  selectedPractitioner,
  isSubmitting,
  onProcess,
  onSubmitForReview
}: ProcessAppointmentFormProps) => {
  const classes = useStyles();

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<AppointmentType>();
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryType | undefined>();
  const [selectedServiceDelivered, setSelectedServiceDelivered] = useState<ServiceDeliveredOptions>(
    ServiceDeliveredOptions.Attended
  );
  const [selectedProviderNo, setSelectedProviderNo] = useState<Provider["providerNumber"]>();
  const [waiveCancellationFee, setWaiveCancellationFee] = useState<boolean>(false);
  const [selectedClaimType, setSelectedClaimType] = useState<ClaimType | undefined>();
  const [selectedMbsCode, setSelectedMbsCode] = useState<string | undefined>();
  const [isPrepareClaimOpen, setIsPrepareClaimOpen] = useState<boolean>(false);
  const [useServiceFee, setIsUseServiceFee] = useState<boolean>(false);

  const [formError, setFormError] = useState<FormError>({
    selectedAppointmentType: false,
    selectedDeliveryType: false,
    selectedServiceDelivered: false,
    selectedClaimType: false,
    selectedMbsCode: false,
    reviewNote: false
  });
  const [reviewNote, setReviewNote] = useState<string | undefined>();

  useEffect(() => {
    if (!isLoading && appointment) {
      setSelectedDeliveryType(appointment.deliveryType);
      setSelectedClaimType(
        appointment?.claimType && [ClaimType.BULK_BILL, ClaimType.REBATE].includes(appointment.claimType)
          ? appointment.claimType
          : undefined
      );
      setIsPrepareClaimOpen(
        appointment.claimType === ClaimType.BULK_BILL || appointment?.claimType === ClaimType.REBATE
      );

      const foundMBS = mbsItemLookup({
        deliveryType: appointment.deliveryType,
        duration: moment(appointment.endTime, TIME_FORMAT).diff(moment(appointment.startTime, TIME_FORMAT), "minutes"),
        clinicianRole: selectedPractitioner.medicare?.role
      });
      if (foundMBS) {
        setSelectedMbsCode(foundMBS.mbsCode);
        setIsUseServiceFee(appointment.claimType === ClaimType.BULK_BILL);
      }

      if (appointmentTypes.length) {
        const appointmentType = appointmentTypes.find((type) => type._id === appointment.sessionTypeId);
        if (appointmentType) {
          setSelectedAppointmentType(appointmentType);
        }
      }
    }
  }, [isLoading, appointment, appointmentTypes, selectedPractitioner.medicare?.role]);

  useEffect(() => {
    if (!isLoading && providers && providers.length) {
      const defaultProviderNo =
        providers.find((option: { default: boolean }) => option.default)?.providerNumber ||
        providers[0]?.providerNumber;
      setSelectedProviderNo(defaultProviderNo);
    }
  }, [isLoading, providers]);

  const needReviewNote = useMemo(() => {
    return (
      selectedAppointmentType?._id !== appointment?.sessionTypeId ||
      selectedDeliveryType !== appointment?.deliveryType ||
      (selectedServiceDelivered === ServiceDeliveredOptions.Attended &&
        selectedClaimType !== appointment?.claimType &&
        appointment?.claimType &&
        [ClaimType.BULK_BILL, ClaimType.REBATE].includes(appointment.claimType)) ||
      waiveCancellationFee
    );
  }, [
    appointment?.claimType,
    appointment?.deliveryType,
    appointment?.sessionTypeId,
    selectedAppointmentType?._id,
    selectedClaimType,
    selectedDeliveryType,
    selectedServiceDelivered,
    waiveCancellationFee
  ]);

  const isSpecialAppointment = useMemo(() => {
    return !appointment?.claimType || ![ClaimType.BULK_BILL, ClaimType.REBATE].includes(appointment.claimType);
  }, [appointment?.claimType]);

  const validateForm = async () => {
    if (
      !selectedAppointmentType ||
      !selectedDeliveryType ||
      !selectedServiceDelivered ||
      (isPrepareClaimOpen && (!selectedClaimType || !selectedMbsCode))
    ) {
      return false;
    }

    return !(needReviewNote && !reviewNote);
  };

  const handleFormError = () => {
    setFormError({
      selectedAppointmentType: !selectedAppointmentType,
      selectedDeliveryType: !selectedDeliveryType,
      selectedServiceDelivered: !selectedServiceDelivered,
      selectedClaimType: isPrepareClaimOpen && !selectedClaimType,
      selectedMbsCode: isPrepareClaimOpen && !selectedMbsCode,
      reviewNote: needReviewNote && !reviewNote
    });
  };

  const handleSubmit = async () => {
    try {
      const isValid = await validateForm();
      if (isValid) {
        const payload = {
          serviceDelivered: selectedServiceDelivered,
          appointmentTypeId: selectedAppointmentType?._id,
          deliveryType: selectedDeliveryType,
          asAdmin: true,
          providerId: selectedProviderNo,
          funder: selectedClaimType,
          mbsCode: selectedMbsCode,
          waiveCancellationFee,
          ...(useServiceFee && {
            invoiceAmount: MBS_CODE_FULL_LIST.find((item) => item.mbsCode === selectedMbsCode)?.benefit || 0
          })
        };
        needReviewNote || isSpecialAppointment ? onSubmitForReview({ ...payload, reviewNote }) : onProcess(payload);
      } else {
        handleFormError();
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleSelectAppointmentType = (appointmentType: AppointmentType) => {
    const isNeedResetPrepareClaim = needResetPrepareClaim({
      selectedAppointmentType: appointmentType
    });

    const foundMbsItem =
      !isNeedResetPrepareClaim && selectedDeliveryType && appointmentType.deliveryOptions.includes(selectedDeliveryType)
        ? mbsItemLookup({
            deliveryType: selectedDeliveryType,
            duration: appointmentType.duration.minutes,
            clinicianRole: selectedPractitioner.medicare?.role
          })
        : undefined;

    setSelectedAppointmentType(appointmentType);
    setSelectedClaimType(!isNeedResetPrepareClaim ? appointmentType?.claimType : undefined);
    setSelectedMbsCode(foundMbsItem?.mbsCode);
    setIsUseServiceFee(
      isNeedResetPrepareClaim ? false : appointmentType.claimType === ClaimType.BULK_BILL && !!foundMbsItem?.mbsCode
    );

    if (
      isNeedResetPrepareClaim ||
      (!isPrepareClaimOpen && selectedServiceDelivered === ServiceDeliveredOptions.Attended)
    ) {
      setIsPrepareClaimOpen(!isNeedResetPrepareClaim);
    }

    if (selectedDeliveryType && !appointmentType?.deliveryOptions.includes(selectedDeliveryType)) {
      setSelectedDeliveryType(undefined);
    }
  };

  const handleSelectDeliveryType = (deliveryType: DeliveryType) => {
    setSelectedDeliveryType(deliveryType);
    const isNeedResetPrepareClaim = needResetPrepareClaim({
      selectedAppointmentType
    });

    const foundMbsItem =
      selectedAppointmentType && !isNeedResetPrepareClaim
        ? mbsItemLookup({
            deliveryType,
            duration: selectedAppointmentType.duration.minutes,
            clinicianRole: selectedPractitioner.medicare?.role
          })
        : undefined;
    setSelectedMbsCode(foundMbsItem?.mbsCode);

    if (formError.selectedDeliveryType || formError.selectedMbsCode) {
      setFormError({
        ...formError,
        ...(formError.selectedDeliveryType && {
          selectedDeliveryType: false
        }),
        ...(formError.selectedMbsCode && {
          selectedMbsCode: false
        })
      });
    }
  };

  const handleSelectServiceDelivered = (serviceDelivered: ServiceDeliveredOptions) => {
    setSelectedServiceDelivered(serviceDelivered);
    setSelectedClaimType(
      serviceDelivered === ServiceDeliveredOptions.Attended ? selectedAppointmentType?.claimType : undefined
    );
    setIsPrepareClaimOpen(
      !!selectedAppointmentType?.claimType &&
        [ClaimType.BULK_BILL, ClaimType.REBATE, ClaimType.DVA].includes(selectedAppointmentType.claimType) &&
        serviceDelivered === ServiceDeliveredOptions.Attended
    );
    setSelectedMbsCode(undefined);
  };

  const handleSelectClaimType = (claimType?: ClaimType) => {
    setSelectedClaimType(claimType);
    if (formError.selectedClaimType) {
      setFormError({
        ...formError,
        selectedClaimType: false
      });
    }
  };

  const handleSelectMBSCode = (mbsCode?: string) => {
    setSelectedMbsCode(mbsCode);
    if (formError.selectedMbsCode) {
      setFormError({
        ...formError,
        selectedMbsCode: false
      });
    }
  };

  const handleSetReviewNote = (value: string) => {
    setReviewNote(value);

    // Reset error
    if (formError.reviewNote) {
      setFormError({
        ...formError,
        reviewNote: false
      });
    }
  };

  return (
    <>
      {isLoading || !appointment ? (
        <LoadingForm />
      ) : (
        <Grid container justifyContent="space-between" className={classes.container}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <div className={classes.titleWrapper}>
                      <span
                        className={classes.title}
                      >{`Your session with ${appointment.clientRecord.clientProfiles?.[0].name} has ended.`}</span>
                      <div className={classes.desc}>
                        {`Process your appointment with ${appointment.clientRecord.clientProfiles?.[0].name} to update the attendance status to ensures the accurate
                  documentation of the appointment details. To streamline invoicing, you can create a pending claim by
                  capturing all relevant information for future processing.`}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AppointmentBasicDetails
                  appointment={appointment}
                  appointmentTypes={appointmentTypes}
                  selectedAppointmentType={selectedAppointmentType}
                  onSelectAppointmentType={handleSelectAppointmentType}
                  selectedDeliveryType={selectedDeliveryType}
                  onSelectDeliveryType={handleSelectDeliveryType}
                  formError={formError}
                />
              </Grid>
              <Grid item xs={12}>
                <AppointmentMoreDetails
                  providers={providers || []}
                  selectedServiceDelivered={selectedServiceDelivered || ServiceDeliveredOptions.Attended}
                  onSelectServiceDelivered={handleSelectServiceDelivered}
                  selectedProviderNo={selectedProviderNo || ""}
                  onSelectProviderNo={(providerNo) => setSelectedProviderNo(providerNo)}
                  waiveCancellationFee={waiveCancellationFee}
                  onSelectWaiveCancellationFee={(waiveCancellationFee) => setWaiveCancellationFee(waiveCancellationFee)}
                  formError={formError}
                  selectedPractitioner={selectedPractitioner}
                />
              </Grid>
              <Grid item xs={12}>
                <AppointmentInvoiceDetails selectedClaimType={selectedClaimType} />
              </Grid>
              <Grid item xs={12}>
                <AppointmentClaimDetails
                  selectedAppointmentType={selectedAppointmentType}
                  selectedServiceDelivered={selectedServiceDelivered}
                  isPrepareClaimOpen={isPrepareClaimOpen}
                  onSetIsPrepareClaimOpen={(isPrepareClaimOpen) => setIsPrepareClaimOpen(isPrepareClaimOpen)}
                  selectedPractitioner={selectedPractitioner}
                  selectedClaimType={selectedClaimType}
                  onSelectClaimType={handleSelectClaimType}
                  selectedMbsCode={selectedMbsCode}
                  onSelectMbsCode={handleSelectMBSCode}
                  formError={formError}
                  useServiceFee={useServiceFee}
                  onSetUseServiceFee={setIsUseServiceFee}
                />
              </Grid>
              {selectedAppointmentType?.claimType &&
                selectedClaimType &&
                selectedClaimType !== selectedAppointmentType.claimType && (
                  <Grid item xs={12}>
                    <div className={classes.changeCodeTypeInfo}>
                      <InfoOutlinedIcon className={classes.infoIcon} />
                      You’re suggesting a code type that is different from the appointment information. This will flag
                      for the admin team to review before the claim is submitted.
                    </div>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <div className={classes.actionWrapper}>
                    {needReviewNote ? (
                      <textarea
                        className={clsx(classes.textarea, formError.reviewNote && classes.errorTextarea)}
                        onBlur={(e) => handleSetReviewNote(e.target.value)}
                        placeholder="Note for review"
                      />
                    ) : (
                      <span className={classes.alertText}>After processing this appointment data not be amended</span>
                    )}
                    <Button
                      disabled={isSubmitting}
                      className={classes.confirmButton}
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      {`${isSubmitting ? "Submitting..." : "Confirm and close"}`}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProcessAppointmentForm;
