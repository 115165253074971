import { Navigate, Route, Routes } from "react-router-dom";
import HostVideoApp from "../components/VideoApp/HostVideoApp";
import ParticipantVideoApp from "../components/VideoApp/ParticipantVideoApp";
import { useAppState } from "../state";
import useConnectionOptions from "../utils/useConnectionOptions/useConnectionOptions";
import { VideoProvider } from "../components/VideoProvider";
import ProcessAppointmentPage from "../components/VideoApp/ProcessAppointment/ProcessAppointmentPage";

const VideoCallRoutes = () => {
  const { setError, setSuccess } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onSuccess={setSuccess} onError={setError}>
      <Routes>
        {/* Protected Routes for video calls */}
        {["/clinicians", "/clinicians/room/:URLRoomName"].map((path, idx) => (
          <Route key={`protected_${idx}`} path={path} element={<HostVideoApp />} />
        ))}

        <Route
          key={`protected_processAppointment}`}
          path={"/clinicians/appointments/:appointmentId/process"}
          element={<ProcessAppointmentPage />}
        />

        {/* Public Routes for video calls */}
        {["/", "/room/:URLRoomName"].map((path, idx) => (
          <Route key={`public_${idx}`} path={path} element={<ParticipantVideoApp />} />
        ))}

        {/* Remaining path will redirect to "/" */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </VideoProvider>
  );
};

export default VideoCallRoutes;
